import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

const isProduction = import.meta.env.MODE === 'production';

if (isProduction) {
  Sentry.init({
    dsn: 'https://2d2acc2707f0b8b5ff1c42615562708e@o4507265932394496.ingest.de.sentry.io/4507265959723089',
    release: process.env.VITE_APP_VERSION,
    environment: process.env.VITE_APP_ENVIRONMENT,

    tracesSampler: ({ name, attributes, parentSampled }) => {

      // sample all transactions if the parent is already sampled
      if (parentSampled) {
        return 1.0
      }

      // sample all actions
      // all actions start as unknown actions
      if (name == 'unknown-action') {
        return 1.0
      }
      // this does not seem to work, but I keep in here anyway
      if (attributes && attributes["sentry.op"]) {
        if (attributes["sentry.op"].includes("action")) {
          return 1.0
        }
      }

      // only sample in production
      if (!isProduction) {
        return 0.0
      }

      // Else sample with 0.1% probability
      return 0.001;
    },

    // This sets the sample rate to be 0, as we have replays already in posthog
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError: HandleClientError = isProduction
  ? handleErrorWithSentry()
  : ({ error }) => {
    console.error(error);
  };
